import { localize } from '@/utils/i18n'

export default [
    {
        id: 'east_pool',
        coords: [133.082542, 42.73409],
        name: localize({
            ru: 'Дальневосточный бассейн',
            en: 'Far East Basin',
            cn: '远东海海盆地'
        }),
        detail: localize({
            ru: 'Наиболее быстрым способом доставки контейнеров из Азии в центральные регионы России является транспортировка через порты Дальневосточного бассейна.',
            en: 'The fastest way to deliver containers from Asia to the central regions of Russia is through the ports of the Far Eastern basin.'
        }),
        indicators: localize({
            ru: [
                {
                    value: '~30',
                    name: 'общего объема перевалки контейнеров',
                    unit: '%'
                },
                {
                    value: '~47',
                    name: 'общего объема перевалки навалочных грузов',
                    unit: '%'
                }
            ],
            en: [
                {
                    value: '~30',
                    name: 'of total container handling',
                    unit: '%'
                },
                {
                    value: '~47',
                    name: 'of total transshipment volume of bulk cargoes',
                    unit: '%'
                }
            ]
        }),
        type: 'hub',
        showInfo: true
    },
    {
        id: 'balt_pool',
        coords: [27.849226, 60.05173151],
        name: localize({
            ru: 'Балтийский бассейн',
            en: 'Baltic Basin',
            cn: '波罗的海盆地'
        }),
        detail: localize({
            ru: 'Основной объем российских контейнерных грузов транспортируется через бассейн Балтийского моря.',
            en: 'The bulk of Russian container cargo is transported through the Baltic Sea basin.'
        }),
        indicators: localize({
            ru: [
                {
                    value: '~51',
                    name: 'общего объема перевалки контейнеров',
                    unit: '%'
                },
                {
                    value: '~30',
                    name: 'общего объема перевалки навалочных грузов',
                    unit: '%'
                }
            ],
            en: [
                {
                    value: '~51',
                    name: 'of total container handling',
                    unit: '%'
                },
                {
                    value: '~30',
                    name: 'of total bulk cargo transshipment',
                    unit: '%'
                }
            ]
        }),
        type: 'hub',
        showInfo: true
    },
    {
        id: 'vostochnaya_stevedoring_company',
        coords: [133.082542, 42.73409],
        name: localize({
            ru: 'Восточная стивидорная компания',
            en: 'Vostochnaya Stevedoring Company',
            cn: 'Vostochnaya Stevedoring Company'
        }),
        link: `terminals/vostochnaya-stevedoring-company/`,
        zoomShowFactor: 8,
        type: 'terminal'
    },
    {
        id: 'MOBY DIK',
        coords: [29.709091, 60.003964],
        name: localize({
            ru: 'Моби Дик',
            en: 'Moby Dik',
            cn: 'Moby Dik'
        }),
        link: `terminals/moby-dik/`,
        zoomShowFactor: 8,
        type: 'terminal'
    },
    {
        id: 'PETROLESPORT',
        coords: [30.053989, 59.998818],
        name: localize({
            ru: 'Петролеспорт',
            en: 'Petrolesport',
            cn: 'Petrolesport'
        }),
        link: `terminals/petrolesport/`,
        zoomShowFactor: 8,
        type: 'terminal'
    },
    {
        id: 'Yanino',
        coords: [30.570488, 59.945039],
        name: localize({
            ru: 'Логистический Парк «Янино»',
            en: 'Yanino Logistics Park',
            cn: 'Yanino Logistics Park'
        }),
        link: `//terminals/yanino-logistics-park/`,
        zoomShowFactor: 8,
        type: 'terminal'
    },
    {
        id: 'FCT',
        coords: [30.202958, 59.881605],
        name: localize({
            ru: 'Первый Контейнерный терминал',
            en: 'First Container Terminal',
            cn: 'First Container Terminal'
        }),
        link: `terminals/first-container-terminal/`,
        zoomShowFactor: 8,
        type: 'terminal'
    },
    {
        id: 'ULCT',
        coords: [28.40566, 59.671425],
        name: localize({
            ru: 'Усть-Лужский контейнерный терминал',
            en: 'Ust-Luga Container Terminal',
            cn: 'Ust-Luga Container Terminal'
        }),
        link: `terminals/ust-luga-container-terminal/`,
        zoomShowFactor: 8,
        type: 'terminal'
    },
    {
        id: 'MTK-HELSINKI',
        coords: [25.194054, 60.21603],
        name: 'MLT-Helsinki',
        link: `terminals/finnish-terminals/`,
        zoomShowFactor: 8,
        type: 'terminal'
    },
    {
        id: 'MTK-KOTKA',
        coords: [26.912556, 60.42758],
        name: 'MLT-Kotka',
        link: `terminals/finnish-terminals/`,
        zoomShowFactor: 8,
        type: 'terminal'
    }
]
