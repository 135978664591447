<template>
    <div class="investors-proposition">
        <div class="proposition-lead">
            <div class="row between-default">
                <div class="proposition-lead__body col-default-8 col-mobile-12">
                    <z-key-indicator
                        noCounter
                        size="l"
                        value="1"
                        prefix="#"
                        name="container terminal operator in&nbsp;the Russian market with a&nbsp;network of&nbsp;well-equipped container and multipurpose terminals in&nbsp;key container gateways"
                    ></z-key-indicator>
                </div>
                <div class="col-default-4 col-mobile-12">
                    <div class="proposition-lead__image">
                        <img src="/images/hero/terminals/terminals_new.png" ref="img">
                    </div>
                </div>
            </div>
        </div>
        <div class="proposition-cases">
            <div class="proposition-cases__items">
                <div class="proposition-cases__item">
                    <z-key-indicator
                        unit="Unique asset base"
                        name="The only player with a&nbsp;network of&nbsp;terminals in&nbsp;all key container gateways"
                    >
                        <template v-slot:icon>
                            <z-icon icon="invest-case-1"></z-icon>
                        </template>
                        <template v-slot:footer>
                            <z-link
                                href="/en/company/structure/"
                                :underline="false"
                                theme="dark-grey"
                            >
                                <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                <span>Group Structure</span>
                            </z-link>
                        </template>
                    </z-key-indicator>
                </div>
                <div class="proposition-cases__item">
                    <z-key-indicator
                        unit="Clear strategy"
                        name="Clear strategy to&nbsp;harness growth of&nbsp;domestic container market and develop new revenue streams and services, focused on&nbsp;optimising cash generation, improving operational efficiency and meeting clients&rsquo; needs"
                    >
                        <template v-slot:icon>
                            <z-icon icon="invest-case-2"></z-icon>
                        </template>
                        <template v-slot:footer>
                            <z-link
                                href="/en/company/strategy/"
                                :underline="false"
                                theme="dark-grey"
                            >
                                <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                <span>Strategy</span>
                            </z-link>
                        </template>
                    </z-key-indicator>
                </div>
                <div class="proposition-cases__item">
                    <z-key-indicator
                        noCounter
                        unit="Leadership in&nbsp;the Russian market and outpacing growth rates"
                        name="In&nbsp;H1 2024, consolidated marine container throughput increased by&nbsp;44.2% y-o-y, while Russian marine container market grew by&nbsp;14.6% y-o-y in&nbsp;the same period"
                    >
                        <template v-slot:icon>
                            <z-icon icon="invest-case-3"></z-icon>
                        </template>
                        <template v-slot:footer>
                            <z-link
                                href="/en/investors/reports-and-results/"
                                :underline="false"
                                theme="dark-grey"
                            >
                                <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                <span>Reports and results</span>
                            </z-link>
                        </template>
                    </z-key-indicator>
                </div>
                <div class="proposition-cases__item">
                    <z-key-indicator
                        unit="Comfortable debt burden combined with high credit rating"
                        name="Net Debt/Adjusted EBITDA equals 2.2x at&nbsp;the end of&nbsp;2023. In&nbsp;the beginning of&nbsp;2024, ACRA assigned to&nbsp;Global Ports credit rating at&nbsp;AA(RU)"
                    >
                        <template v-slot:icon>
                            <z-icon icon="invest-case-4"></z-icon>
                        </template>
                        <template v-slot:footer>
                            <z-link
                                href="/en/investors/reports-and-results/"
                                :underline="false"
                                theme="dark-grey"
                            >
                                <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                <span>Reports and results</span>
                            </z-link>
                        </template>
                    </z-key-indicator>
                </div>
                <div class="proposition-cases__item">
                    <z-key-indicator
                        unit="Experienced team"
                        name="Experienced team with extensive industry knowledge and technological knowhow"
                    >
                        <template v-slot:icon>
                            <z-icon icon="invest-case-5"></z-icon>
                        </template>
                        <template v-slot:footer>
                            <z-link
                                href="/en/company/governance/management/"
                                :underline="false"
                                theme="dark-grey"
                            >
                                <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                <span>Management</span>
                            </z-link>
                        </template>
                    </z-key-indicator>
                </div>
                <div class="proposition-cases__item">
                    <z-key-indicator
                        unit="Strong corporate governance"
                        name="In-depth expertise of&nbsp;key shareholder Delo Group, a&nbsp;leader in&nbsp;container logistics (sea and rail) and deep-sea grain transshipment in&nbsp;Russia"
                    >
                        <template v-slot:icon>
                            <z-icon icon="invest-case-6"></z-icon>
                        </template>
                        <template v-slot:footer>
                            <z-link
                                href="/en/company/governance/"
                                :underline="false"
                                theme="dark-grey"
                            >
                                <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                <span>Corporate governance</span>
                            </z-link>
                        </template>
                    </z-key-indicator>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted () {
        objectFitPolyfill(this.$refs.img)
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
