<template>
    <div class="history">
        <div class="history__head">
            <p class="history__text" v-html="text.lead"></p>
        </div>
        <div class="history__container">
            <div class="history__content">
                <div class="history__wrapper"
                    v-for="(decade, index) in list"
                    :key="index"
                    :id="decade.key"
                >
                    <z-accordion>
                        <z-accordion-item :id="(index + 1).toString()">
                            <template v-slot:icon>
                                <p class="history__icon-text">Learn more</p>
                                <z-icon name="arrow" dir="down" height="16"></z-icon>
                            </template>
                            <template v-slot:header>
                                <div
                                    class="history-item history-item--special"
                                    :style="`background-image: url(${imgPath}${decade.items[0].img.special})`"
                                >
                                    <div class="history-item__content">
                                        <div class="history-item__wrapper history-item__wrapper--special">
                                            <h5 class="history__title" v-html="decade.name"></h5>
                                            <div class="history-item__info" v-html="decade.items[0].text"></div>
                                        </div>
                                    </div>
                                </div>

                            </template>
                            <template v-slot:body>
                                <div
                                    class="history-item"
                                    v-for="(item, index) in decade.items"
                                    :key="index"
                                    :class="{'history-item--special history-item--2008': item.year === 2008 }"
                                    :style="`${item.year === 2008 ? `background-image: url(${imgPath}${item.img.special})` : ''}`"
                                >
                                    <template v-if="index !== 0">
                                        <div class="history-item__content" v-if="item.year === 2008">
                                            <div class="history-item__wrapper history-item__wrapper--special">
                                                <h5 class="history__title" v-html="item.year"></h5>
                                                <div class="history-item__info" v-html="item.text"></div>
                                            </div>
                                        </div>
                                        <div class="history-item__content" v-else>
                                            <div class="history-item__year" v-html="item.year"></div>
                                            <div class="history-item__wrapper">
                                                <div class="history-item__img">
                                                    <picture>
                                                        <source :srcset="imgPath + item.img.mobile" media="(max-width: 480px)">
                                                        <source :srcset="imgPath + item.img.tablet" media="(max-width: 1024px)">
                                                        <img :src="imgPath + item.img.desktop" alt="">
                                                    </picture>
                                                </div>
                                                <div class="history-item__info" v-html="item.text"></div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </z-accordion-item>
                    </z-accordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'history',
    data () {
        return {
            imgPath: '/images/company/history/',
            text: {
                lead: localize({
                    'ru': 'Холдинг Глобал Портс работает на&nbsp;российском контейнерном рынке более 20&nbsp;лет. Сегодня компания объединяет портовые комплексы, расположенные в&nbsp;Балтийском и&nbsp;Дальневосточном морских бассейнах, и&nbsp;является оператором &#8470;&nbsp;1&nbsp;в России по&nbsp;пропускной способности и&nbsp;грузообороту контейнерных терминалов.',
                    'en': 'Global Ports has been operating in&nbsp;the Russian container market for over 20&nbsp;years. Today, the company unites port complexes located in&nbsp;the Baltic and the Far Eastern sea basins and is&nbsp;the #1 Container Terminal Operator in&nbsp;Russia in&nbsp;terms of&nbsp;cargo throughput and capacity.'
                })
            },
            list: [
                {
                    key: 2020,
                    name: localize({
                        'ru': 'От&nbsp;отдельных активов к&nbsp;сети высокотехнологичных терминалов',
                        'en': 'From individual assets to&nbsp;a&nbsp;network of&nbsp;high-tech terminals'
                    }),
                    items: [
                        {
                            year: '',
                            text: localize({
                                'ru': 'В&nbsp;результате создания холдинга Глобал Портс в&nbsp;России появляется оператор морских терминалов, не&nbsp;имеющий аналогов на&nbsp;рынке, предоставляющий клиентам единый стандарт сервиса от&nbsp;Балтики до&nbsp;Дальнего Востока. Глобал Портс управляет сетью из 5 высокотехнологичных морских терминалов в&nbsp;России и&nbsp;2 - в&nbsp;Финляндии, а&nbsp;также логистическим комплексом под Санкт-Петербургом, являясь приоритетным партнером для своих клиентов.',
                                'en': 'The creation of&nbsp;the Global Ports Holding resulted in&nbsp;a&nbsp;marine terminal operator with no&nbsp;equal anywhere in&nbsp;the Russian market, providing customers with a&nbsp;unified standard of&nbsp;service from the Baltic to&nbsp;the Far East. Global Ports now manages and develops a&nbsp;network of&nbsp;5 high-tech sea terminals in&nbsp;Russia and 2 in&nbsp;Finland, as&nbsp;well as&nbsp;a&nbsp;logistics complex near St. Petersburg, being a&nbsp;priority partner for its customers.'
                            }),
                            img: {
                                desktop: 'history-accordeon-1.jpg',
                                tablet: 'history-accordeon-1.jpg',
                                mobile: 'history-accordeon-1.jpg',
                                special: 'history-accordeon-1.jpg'
                            },
                            special: true
                        },
                        {
                            year: 2023,
                            text: localize({
                                'ru': 'Глобал Портс редомицилировался в&nbsp;Россию. Компания зарегистрировалась в&nbsp;САР на&nbsp;острове Русский.<br /><br />Терминалы Глобал Портс за&nbsp;год перевалили рекордный объем навалочных грузов&nbsp;&mdash; 6,4 млн тонн.',
                                'en': 'Global Ports completed its redomiciliation to&nbsp;Russia. The company was registered on&nbsp;Russky Island in&nbsp;the Primorsky Territory.<br /><br />Global Ports handled a&nbsp;record annual volume of&nbsp;non-containerised cargo&nbsp;&mdash; 6.4 million tonnes.'
                            }),
                            img: {
                                desktop: '2023.jpg',
                                tablet: '2023.jpg',
                                mobile: '2023.jpg'
                            }
                        },
                        {
                            year: 2022,
                            text: localize({
                                'ru': 'Группа компаний &laquo;Дело&raquo; увеличила свою долю в&nbsp;Глобал Портс до&nbsp;61,5%.<br /><br />Глобал Портс консолидировал Логистический парк &laquo;Янино&raquo; и&nbsp;терминал &laquo;Моби Дик&raquo;. Таким образом, холдинг получил полный контроль над всеми российскими терминалами.<br /><br />Глобал Портс начал перевалку удобрений на&nbsp;терминалах в&nbsp;Балтийском бассейне.',
                                'en': 'Delo Group increased its stake in&nbsp;Global Ports to&nbsp;61.5%.<br /><br />Global Ports consolidated Yanino Logistics Complex and Mody Dik. With that, the holding received full control over all Russian ports.<br /><br />Global Ports started to&nbsp;handle fertilizers at&nbsp;terminals in&nbsp;the Baltic basin.'
                            }),
                            img: {
                                desktop: '2022.jpg',
                                tablet: '2022.jpg',
                                mobile: '2022.jpg'
                            }
                        },
                        {
                            year: 2021,
                            text: localize({
                                'ru': '&laquo;Восточная стивидорная компания&raquo; прекращает предоставление услуг по&nbsp;перевалке угля, чтобы сосредоточиться на&nbsp;контейнерных перевозках.',
                                'en': 'Vostochnaya Stevedoring Company halts coal handling services to&nbsp;focus on&nbsp;container cargo.'
                            }),
                            img: {
                                desktop: '2021.jpg',
                                tablet: '2021.jpg',
                                mobile: '2021.jpg'
                            }
                        },
                        {
                            year: 2020,
                            text: localize({
                                'ru': '&laquo;Первый контейнерный терминал&raquo; первым в&nbsp;России перешел на&nbsp;полностью безбумажное оформление экспортных и&nbsp;импортных грузов.',
                                'en': 'First Container Terminal became the first terminal in&nbsp;Russia to&nbsp;launch a&nbsp;completely paperless processing of&nbsp;export and import cargo.'
                            }),
                            img: {
                                desktop: '2020.jpg',
                                tablet: '2020.jpg',
                                mobile: '2020.jpg'
                            }
                        },
                        {
                            year: 2019,
                            text: localize({
                                'ru': 'Запущен Единый клиентский портал Глобал Портс. Для обеспечения прямой оперативной связи с&nbsp;клиентами начинает работу сервисный call-центр Глобал Портс.<br /><br /> Глобал Портс завершает строительство температурного перетарочного комплекса повышенной производительности на&nbsp;терминале &laquo;Петролеспорт&raquo;. Современное оснащение комплекса позволяет гарантировать клиентам сроки обработки и&nbsp;сохранность груза, полностью исключив потерю качества или порчу товара в&nbsp;процессе перетарки.',
                                'en': 'The Global Ports Single Customer Portal was launched. For the purpose of&nbsp;ensuring direct operational communications with customers, the Global Ports Service Call Centre was launched.<br /><br /> Global Ports completed the construction of&nbsp;a&nbsp;temperature cross dock facility of&nbsp;increased productivity at&nbsp;Petrolesport terminal. The facility’s modern equipment allows it&nbsp;to&nbsp;provide customers a&nbsp;guarantee of&nbsp;the processing time and safety of&nbsp;the cargo, eliminating the risk of&nbsp;loss of&nbsp;quality or&nbsp;damage to&nbsp;goods during the re-stuffing process.'
                            }),
                            img: {
                                desktop: '2019.jpg',
                                tablet: '2019.jpg',
                                mobile: '2019.jpg'
                            }
                        },
                        {
                            year: 2018,
                            text: localize({
                                'ru': 'Группа компаний &laquo;Дело&raquo;, один из&nbsp;крупнейших частных транспортно-логистических холдингов России, приобретает 30,75% акций Глобал Портс.<br /><br /> &laquo;Усть-Лужский контейнерный терминал&raquo; начинает операции по&nbsp;перевалке угля. Пропускная способность угольного комплекса&nbsp;&mdash; 1&nbsp;млн тонн в&nbsp;год.',
                                'en': 'Delo Group, one of&nbsp;the largest private transport and logistics holdings in&nbsp;Russia, acquired a&nbsp;30.75% stake in&nbsp;Global Ports.<br /><br /> Ust-Luga Container Terminal started coal handling operations. The throughput capacity of&nbsp;the coal complex was one million tonnes per year.'
                            }),
                            img: {
                                desktop: '2018.jpg',
                                tablet: '2018.jpg',
                                mobile: '2018.jpg'
                            }
                        },
                        {
                            year: 2017,
                            text: localize({
                                'ru': 'Мощности &laquo;Восточной стивидорной компании&raquo; по&nbsp;перевалке угля увеличены до&nbsp;1,6 миллиона тонн.',
                                'en': 'The coal handling capacities of&nbsp;Vostochnaya Stevedoring Company were increased to&nbsp;1.6 million tonnes.'
                            }),
                            img: {
                                desktop: '2017.jpg',
                                tablet: '2017.jpg',
                                mobile: '2017.jpg'
                            }
                        },
                        {
                            year: 2015,
                            text: localize({
                                'ru': '&laquo;Первый контейнерный терминал&raquo; стал первой стивидорной компанией в&nbsp;России, обеспечившей полностью электронный документооборот на&nbsp;всех этапах обработки груза.',
                                'en': 'First Container Terminal became the first stevedoring company in&nbsp;Russia to&nbsp;provide a&nbsp;fully digital document flow at&nbsp;all stages of&nbsp;cargo handling.'
                            }),
                            img: {
                                desktop: '2015.jpg',
                                tablet: '2015.jpg',
                                mobile: '2015.jpg'
                            }
                        },
                        {
                            year: 2014,
                            text: localize({
                                'ru': 'Мощность контейнерного терминала &laquo;Восточной стивидорной компании&raquo; увеличена до&nbsp;650&nbsp;000&nbsp;TEU за&nbsp;счет сокращения сроков хранения грузов и&nbsp;оптимизации производственных процессов.',
                                'en': 'Vostochnaya Stevedoring Company’s container terminal capacity was increased to&nbsp;650,000&nbsp;TEU due to&nbsp;the reduction of&nbsp;cargo storage terms and the optimisation of&nbsp;operational processes.'
                            }),
                            img: {
                                desktop: '2014.jpg',
                                tablet: '2014.jpg',
                                mobile: '2014.jpg'
                            }
                        },
                        {
                            year: 2013,
                            text: localize({
                                'ru': 'В&nbsp;состав Глобал Портс входят терминалы &laquo;Национальной контейнерной компании&raquo;&nbsp;&mdash; &laquo;Первый контейнерный терминал&raquo; и&nbsp;&laquo;Усть-Лужский контейнерный терминал&raquo;.',
                                'en': 'Global Ports acquired National Container Company, therefore incorporating First Container Terminal and Ust-Luga Container Terminal into the Group.'
                            }),
                            img: {
                                desktop: '2013.jpg',
                                tablet: '2013.jpg',
                                mobile: '2013.jpg'
                            }
                        },
                        {
                            year: 2012,
                            text: localize({
                                'ru': 'Одним из&nbsp;контролирующих акционеров Глобал Портс становится ведущий мировой оператор морских и&nbsp;тыловых терминалов компания APM Terminals, входящая в&nbsp;состав A.P. Moller-Maersk A/S',
                                'en': 'APM&nbsp;Terminals, a&nbsp;leading global operator of&nbsp;maritime and inland terminals and a&nbsp;member of&nbsp;A.P. Moller-Maersk A/S, became one of&nbsp;the controlling shareholders in&nbsp;Global Ports.'
                            }),
                            img: {
                                desktop: '2012.jpg',
                                tablet: '2012.jpg',
                                mobile: '2012.jpg'
                            }
                        },
                        {
                            year: 2011,
                            text: localize({
                                'ru': 'Завершено строительство &laquo;Усть-Лужского контейнерного терминала&raquo; в&nbsp;новом глубоководном порту Усть-Луга, расположенном в&nbsp;100 километрах к&nbsp;западу от&nbsp;Санкт-Петербурга.<br /><br />На&nbsp;территории &laquo;Восточной стивидорной компании&raquo; начинает работу специализированный угольный комплекс пропускной способностью до&nbsp;1&nbsp;млн тонн в&nbsp;год.<br /><br /> Глобал Портс проводит успешное размещение глобальных депозитарных расписок на&nbsp;Лондонской фондовой бирже.',
                                'en': 'The construction of&nbsp;the Ust-Luga Container Terminal in&nbsp;the new deep-water port of&nbsp;Ust-Luga, located 100 kilometres west of&nbsp;St. Petersburg, was completed.<br /><br /> A&nbsp;specialised coal complex with a&nbsp;throughput capacity of&nbsp;up&nbsp;to&nbsp;one million tonnes per year was launched on&nbsp;the territory of&nbsp;the Vostochnaya Stevedoring Company.<br /><br /> Global Ports successfully listed its GDRs on&nbsp;the London Stock Exchange.'
                            }),
                            img: {
                                desktop: '2011.jpg',
                                tablet: '2011.jpg',
                                mobile: '2011.jpg'
                            }
                        },
                        {
                            year: 2010,
                            text: localize({
                                'ru': 'Под Санкт-Петербургом введен в&nbsp;эксплуатацию многофункциональный логистический комплекс &laquo;Логистический парк &laquo;Янино&raquo;.',
                                'en': 'The multifunctional logistics complex Yanino Logistic Park was put into operation near St. Petersburg.'
                            }),
                            img: {
                                desktop: '2010.jpg',
                                tablet: '2010.jpg',
                                mobile: '2010.jpg'
                            }
                        },
                        {
                            year: 2009,
                            text: localize({
                                'ru': '&laquo;Петролеспорт&raquo; завершает базовый этап программы развития специализированного паромного терминала. Модернизированы мощности по&nbsp;приему и&nbsp;хранению паромных грузов, введена ИТ-система управления терминалом, построены специализированные открытые складские площадки для легковых машин общей площадью свыше 16&nbsp;га. Новый терминал становится крупнейшим в&nbsp;России комплексом по&nbsp;обработке ро-ро грузов.<br /><br /> &laquo;Первый контейнерный терминал&raquo; первым в&nbsp;России внедряет систему Единой цифровой подписи и&nbsp;начинает переход на&nbsp;электронный документооборот.',
                                'en': 'Petrolesport completed the base stage of&nbsp;its specialised ferry terminal development programme. The capacities for receiving and storing ferry cargo were modernised, an&nbsp;IT-control system for the terminal was introduced, and specialised open storage yards for passenger cars with a&nbsp;total area of&nbsp;over 16&nbsp;hectares were built. The new terminal becomes the largest ro-ro cargo handling complex in&nbsp;Russia.<br /><br /> First Container Terminal was the first in&nbsp;Russia to&nbsp;introduce the Unified Digital Signature system and begin the transition to&nbsp;electronic document flow.'
                            }),
                            img: {
                                desktop: '2009.jpg',
                                tablet: '2009.jpg',
                                mobile: '2009.jpg'
                            }
                        },
                        {
                            year: 2008,
                            text: localize({
                                'ru': 'Создание Глобал Портс: холдинг объединяет терминалы &laquo;Петролеспорт&raquo;, &laquo;Восточная стивидорная компания&raquo;, &laquo;Моби Дик&raquo;, терминалы MLT в&nbsp;Финляндии, строящийся логистический комплекс &laquo;Янино&raquo; и&nbsp;становится одним из&nbsp;ведущих операторов контейнерных терминалов в&nbsp;России.',
                                'en': 'Global Ports Holding united the terminals Petrolesport, Vostochnaya Stevedoring Company, Moby Dik, MLT terminals in&nbsp;Finland, with Yanino Logistics Complex under construction, and became one of&nbsp;the leading container terminal operators in&nbsp;Russia.'
                            }),
                            img: {
                                desktop: '2008.jpg',
                                tablet: '2008.jpg',
                                mobile: '2008.jpg',
                                special: '2008.jpg'
                            },
                            special: true
                        }
                    ]
                },
                {
                    key: 2007,
                    name: localize({
                        'ru': 'Становление активов',
                        'en': 'Formation of&nbsp;Holding assets'
                    }),
                    items: [
                        {
                            year: '',
                            text: localize({
                                'ru': 'В&nbsp;период 1990-х годов и начала 2000-х годов в&nbsp;результате приватизации и&nbsp;ряда преобразований созданы ключевые компании, управляющие терминалами холдинга Глобал Портс сегодня. Параллельно шли процессы адаптации портовых комплексов к&nbsp;рыночным условиям, расширение номенклатуры обрабатываемых грузов, развитие ИТ-систем.',
                                'en': 'From the 1990s to&nbsp;the early 2000s, the key companies that operate the terminals of&nbsp;the Global Ports Holding today were created as&nbsp;a&nbsp;result of&nbsp;privatisation and series of&nbsp;transformations. At&nbsp;the same time, the adaptation of&nbsp;port complexes to&nbsp;market conditions, the expansion of&nbsp;the range of&nbsp;handled cargo, and the development of&nbsp;IT systems were carried out.'
                            }),
                            img: {
                                desktop: 'history-accordeon-2.jpg',
                                tablet: 'history-accordeon-2.jpg',
                                mobile: 'history-accordeon-2.jpg',
                                special: 'history-accordeon-2.jpg'
                            },
                            special: true
                        },
                        {
                            year: 2007,
                            text: localize({
                                'ru': 'Утверждена программа модернизации и&nbsp;расширения мощностей &laquo;Петролеспорта&raquo;, предусматривающая создание современного комплекса портовых терминалов.',
                                'en': 'The modernisation and expansion programme of&nbsp;Petrolesport’s capacities was approved, providing for the creation of&nbsp;a&nbsp;modern complex of&nbsp;port terminals.'
                            }),
                            img: {
                                desktop: '2007.jpg',
                                tablet: '2007.jpg',
                                mobile: '2007.jpg'
                            }
                        },
                        {
                            year: 2004,
                            text: localize({
                                'ru': 'В&nbsp;Восточном порту начинает работу новый контейнерный терминал&nbsp;&mdash; ООО &laquo;Восточная стивидорная компания&raquo;.<br /><br /> Служба информационных технологий &laquo;Первого контейнерного терминала&raquo; преобразована в&nbsp;ООО &laquo;Российские логистические информационные системы&raquo; (РОЛИС). РОЛИС &mdash; специализированная компания, которая занимается разработкой ИТ-решений в&nbsp;сфере управления портовыми терминалами и&nbsp;в&nbsp;области информационного обеспечения бизнес-процессов.',
                                'en': 'A&nbsp;new container terminal, Vostochnaya Stevedoring Company LLC, started operating in&nbsp;Vostochny port.<br /><br /> The information technology service of&nbsp;the First Container Terminal was reorganised into LLC Russian Logistic Information Systems (ROLIS)&nbsp;— a&nbsp;specialised&nbsp;IT solutions developer for port terminal management and information support for business processes.'
                            }),
                            img: {
                                desktop: '2004.jpg',
                                tablet: '2004.jpg',
                                mobile: '2004.jpg'
                            }
                        },
                        {
                            year: 2002,
                            text: localize({
                                'ru': 'Для развития сети контейнерных терминалов на&nbsp;территории Российской Федерации на&nbsp;базе &laquo;Первого контейнерного терминала&raquo; создана &laquo;Национальная контейнерная компания&raquo;.<br /><br /> В&nbsp;Кронштадте введен в&nbsp;эксплуатацию паромно-перегрузочный комплекс &laquo;Моби Дик&raquo;.',
                                'en': 'In&nbsp;order to&nbsp;develop a&nbsp;network of&nbsp;container terminals in&nbsp;the Russian Federation, National Container Company was established, with First Container Terminal as&nbsp;its first asset.<br /><br /> The cargo terminal, Moby Dik, was put into operation in&nbsp;Kronstadt.'
                            }),
                            img: {
                                desktop: '2002.jpg',
                                tablet: '2002.jpg',
                                mobile: '2002.jpg'
                            }
                        },
                        {
                            year: 2001,
                            text: localize({
                                'ru': '&laquo;Первый контейнерный терминал&raquo; завершает внедрение интегрированной информационной системы, которая охватывает все бизнес- и&nbsp;производственные процессы предприятия и&nbsp;становится первой в&nbsp;России информационной системой управления контейнерным терминалом.<br /><br /> &laquo;Петролеспорт&raquo; расширяет номенклатуру обрабатываемых грузов: к&nbsp;традиционным лесным грузам и&nbsp;металлолому добавлены контейнеры, рефрижераторные, генеральные грузы и&nbsp;грузы ро-ро.',
                                'en': 'First Container Terminal completed the implementation of&nbsp;an&nbsp;integrated information system which covered all operational and financial processes of&nbsp;the business and became the first information system for managing a&nbsp;container terminal in&nbsp;Russia.<br /><br /> Petrolesport expanded its range of&nbsp;handled cargo: containers, refrigerated containers, general and ro-ro cargo were added to&nbsp;the more traditional timber and scrap metal.'
                            }),
                            img: {
                                desktop: '2001.jpg',
                                tablet: '2001.jpg',
                                mobile: '2001.jpg'
                            }
                        },
                        {
                            year: 1998,
                            text: localize({
                                'ru': 'ОАО &laquo;Морской порт Санкт-Петербург&raquo; проводит реструктуризацию&nbsp;&mdash; образовано несколько дочерних акционерных обществ со&nbsp;специализацией по&nbsp;видам грузов. На&nbsp;базе контейнерного терминала, работающего в&nbsp;третьем районе порта, в&nbsp;октябре создан ЗАО &laquo;Первый контейнерный терминал&raquo;.',
                                'en': 'JSC Sea Port of&nbsp;St. Petersburg undertook restructuring and several subsidiary joint-stock companies, specialising by&nbsp;types of&nbsp;cargo, were established. First Container Terminal JSC was established in&nbsp;October on&nbsp;the base of&nbsp;a&nbsp;container terminal operating in&nbsp;the third district of&nbsp;the port.'
                            }),
                            img: {
                                desktop: '1998.jpg',
                                tablet: '1998.jpg',
                                mobile: '1998.jpg'
                            }
                        },
                        {
                            year: 1992,
                            text: localize({
                                'ru': 'В&nbsp;порту Санкт-Петербург основано ОАО &laquo;Петролеспорт&raquo;, специализированная стивидорная компания по&nbsp;перевалке лесных грузов.',
                                'en': 'Petrolesport JSC, a&nbsp;specialised stevedoring company for the handling of&nbsp;timber cargo, was founded in&nbsp;the port of&nbsp;St. Petersburg.'
                            }),
                            img: {
                                desktop: '1992.jpg',
                                tablet: '1992.jpg',
                                mobile: '1992.jpg'
                            }
                        }
                    ]
                },
                {
                    key: 1970,
                    name: localize({
                        'ru': 'Первые контейнерные площадки в&nbsp;СССР',
                        'en': 'The first container yards in&nbsp;the USSR'
                    }),
                    items: [
                        {
                            year: '',
                            text: localize({
                                'ru': 'В&nbsp;1970-е годы в&nbsp;Балтийском и&nbsp;Дальневосточном бассейнах созданы специализированные площадки для перевалки контейнеров, которые впоследствии стали частью холдинга Глобал Портс.',
                                'en': 'In&nbsp;the 1970s, specialised yards for container handling were created in&nbsp;the Baltic and Far Eastern basins, which later became part of&nbsp;the Global Ports Holding.'
                            }),
                            img: {
                                desktop: 'history-accordeon-3.jpg',
                                tablet: 'history-accordeon-3.jpg',
                                mobile: 'history-accordeon-3.jpg',
                                special: 'history-accordeon-3.jpg'
                            },
                            special: true
                        },
                        {
                            year: 1976,
                            text: localize({
                                'ru': 'В&nbsp;новом глубоководном морском торговом порту &laquo;Восточный&raquo; построен контейнерный терминал, преемником которого является &laquo;Восточная стивидорная компания&raquo;. Годовая пропускная способность терминала составляла 70&nbsp;тысяч контейнеров международного стандарта. С&nbsp;конца 1977 года планирование, учет, контроль и&nbsp;оперативное управление терминалом осуществляется с&nbsp;помощью ЭВМ.',
                                'en': 'A&nbsp;new container terminal was built in&nbsp;the new deep-water commercial seaport Vostochny, which became the Vostochnaya Stevedoring Company. The terminal’s annual throughput was 70,000 containers of&nbsp;international standard. Since late 1977, the planning, accounting, control and operational management of&nbsp;the terminal has been automated.'
                            }),
                            img: {
                                desktop: '1976.jpg',
                                tablet: '1976.jpg',
                                mobile: '1976.jpg'
                            }
                        },
                        {
                            year: 1973,
                            text: localize({
                                'ru': 'На&nbsp;причалах третьего района Ленинградского морского торгового порта начал работу первый в СССР специализированный терминал для обработки грузов в&nbsp;контейнерах, впоследствии преобразованный в&nbsp;&laquo;Первый контейнерный терминал&raquo;. Также в&nbsp;то&nbsp;время на&nbsp;причалах третьего района порта обрабатывались авто- и&nbsp;сельхозтехника, металлы, лесные и&nbsp;генеральные грузы. Пропускная способность контейнерной площадки составляла около 80&nbsp;000 контейнеров международного стандарта.',
                                'en': 'The first specialised terminal, aimed at&nbsp;handling cargo in&nbsp;containers (which was later transformed into the First Container Terminal), began operations at&nbsp;the berths of&nbsp;the third district of&nbsp;the Leningrad Sea Commercial Port. The berths also handled auto and agricultural machinery, metals, timber and general cargo at&nbsp;the time. The throughput capacity of&nbsp;the container yard was about 80,000 containers of&nbsp;international standard.'
                            }),
                            img: {
                                desktop: '1973.jpg',
                                tablet: '1973.jpg',
                                mobile: '1973.jpg'
                            }
                        }
                    ]
                }
            ]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
