// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import { TippyComponent } from 'vue-tippy'
import App from './App'
import store from './store/'

import '@/utils/filter' // global
import '@/utils/equalHeights' // global
import '@/utils/polyfills' // global
import '@/utils/modernizr-custom' // global
import '@/utils/correctExtensions' // global

import { AppDeviceEnquire } from '@/utils/mixin'
import { showNotyfications } from '@/utils/notifications'
import { VueAxios } from '@/utils/request'

/* Components */
import NavigationMain from '@/components/NavigationMain'
import NavigationMobile from '@/components/NavigationMobile'
import NavigationLeft from '@/components/NavigationLeft'
import NavigationLeftTablet from '@/components/NavigationLeftTablet'
import NavigationBottom from '@/components/NavigationBottom'
import Card from '@/components/Card'
import CardIcon from '@/components/CardIcon'
import NewsFilters from '@/components/NewsFilters'
import Bods from '@/components/Bods'
import CardSlider from '@/components/CardSlider'
import Gallery from '@/components/Gallery'
import GallerySlide from '@/components/GallerySlide'
import TerminalsMap from '@/components/TerminalsMap'
import FastSearch from '@/components/FastSearch'
import MediaFilters from '@/components/MediaFilters'
import PageNotFound from '@/components/PageNotFound'
import CookiePopup from '@/components/CookiePopup'
import BusinessModelSlides from '@/components/BusinessModelSlides'
import LogisticMap from '@/components/LogisticMap'
import LogisticScheme from '@/components/LogisticScheme'
import Slider from '@/components/Slider'
import FileInput from '@/components/FileInput'
import Notification from '@/components/Notification'
import WidgetRoseltorg from '@/components/WidgetRoseltorg'

/* Views */
import Terminals from '@/views/Terminals'
import TerminalsDetail from '@/views/TerminalsDetail'
import News from '@/views/News'
import NewsDetail from '@/views/NewsDetail'
import Services from '@/views/Services'
import ServicesDetail from '@/views/ServicesDetail'
import ReportsAndResults from '@/views/ReportsAndResults'
import Management from '@/views/Management'
import BoardOfDirectors from '@/views/BoardOfDirectors'
import Mainpage from '@/views/Mainpage'
import OnlineServices from '@/views/OnlineServices'
import Documents from '@/views/Documents'
import DisclosureDocuments from '@/views/DisclosureDocuments'
import SustainabilityPeople from '@/views/SustainabilityPeople'
import SustainabilityReports from '@/views/SustainabilityReports'
import ShareholdersStructure from '@/views/ShareholdersStructure'
import Contacts from '@/views/Contacts'
import CompanyAbout from '@/views/CompanyAbout'
import ContactsItem from '@/views/ContactsItem'
import Search from '@/views/Search'
import Company from '@/views/Company'
import Investors from '@/views/Investors'
import Committees from '@/views/Committees'
import InvestorsProposition from '@/views/InvestorsProposition'
import Governance from '@/views/Governance'
import SimpleDocument from '@/views/SimpleDocument'
import NewsAndMedia from '@/views/NewsAndMedia'
import Client from '@/views/Client'
import Sustainability from '@/views/Sustainability'
import Ratings from '@/views/Ratings'
import About from '@/views/About'
import MediaBooklets from '@/views/MediaBooklets'
import MediaPhotos from '@/views/MediaPhotos'
import MediaPhotosDetail from '@/views/MediaPhotosDetail'
import MediaIdentity from '@/views/MediaIdentity'
import MediaIdentityDetail from '@/views/MediaIdentityDetail'
import SiteMap from '@/views/SiteMap'
import InvestorsNews from '@/views/InvestorsNews'
import Subscribe from '@/views/Subscribe'
import Employee from '@/views/Employee'
import Careers from '@/views/Careers'
import CareersVacancies from '@/views/CareersVacancies'
import History from '@/views/History'
import CompanyStrategy from '@/views/CompanyStrategy'
import BusinessModel from '@/views/BusinessModel'
import MediaKit from '@/views/MediaKit'
import MediaKitDetail from '@/views/MediaKitDetail'
import ForwardingAndLogisticsForm from '@/views/ForwardingAndLogisticsForm'
import SpecialEquipment from '@/views/SpecialEquipment'
import SpecialEquipmentDetail from '@/views/SpecialEquipmentDetail'
import SpecialEquipmentPrice from '@/views/SpecialEquipmentPrice'

/* Components */
Vue.component('tippy-tooltip', TippyComponent)
Vue.component('navigation-main', NavigationMain)
Vue.component('navigation-mobile', NavigationMobile)
Vue.component('navigation-left', NavigationLeft)
Vue.component('navigation-left-tablet', NavigationLeftTablet)
Vue.component('navigation-bottom', NavigationBottom)
Vue.component('card', Card)
Vue.component('card-icon', CardIcon)
Vue.component('news-filters', NewsFilters)
Vue.component('bods', Bods)
Vue.component('card-slider', CardSlider)
Vue.component('gallery', Gallery)
Vue.component('gallery-slide', GallerySlide)
Vue.component('terminals-map', TerminalsMap)
Vue.component('fast-search', FastSearch)
Vue.component('media-filters', MediaFilters)
Vue.component('page-not-found', PageNotFound)
Vue.component('cookie-popup', CookiePopup)
Vue.component('business-model-slides', BusinessModelSlides)
Vue.component('logistic-map', LogisticMap)
Vue.component('logistic-scheme', LogisticScheme)
Vue.component('slider', Slider)
Vue.component('file-input', FileInput)
Vue.component('notification', Notification)
Vue.component('widget-roseltorg', WidgetRoseltorg)

/* Views */
Vue.component('terminals', Terminals)
Vue.component('terminals-detail', TerminalsDetail)
Vue.component('news', News)
Vue.component('news-detail', NewsDetail)
Vue.component('services', Services)
Vue.component('services-detail', ServicesDetail)
Vue.component('reports-and-results', ReportsAndResults)
Vue.component('management', Management)
Vue.component('board-of-directors', BoardOfDirectors)
Vue.component('mainpage', Mainpage)
Vue.component('online-services', OnlineServices)
Vue.component('documents', Documents)
Vue.component('disclosure-documents', DisclosureDocuments)
Vue.component('sustainability-people', SustainabilityPeople)
Vue.component('sustainability-reports', SustainabilityReports)
Vue.component('shareholders-structure', ShareholdersStructure)
Vue.component('contacts', Contacts)
Vue.component('company-about', CompanyAbout)
Vue.component('contacts-item', ContactsItem)
Vue.component('search', Search)
Vue.component('company', Company)
Vue.component('investors', Investors)
Vue.component('committees', Committees)
Vue.component('investors-proposition', InvestorsProposition)
Vue.component('governance', Governance)
Vue.component('simple-document', SimpleDocument)
Vue.component('news-and-media', NewsAndMedia)
Vue.component('client', Client)
Vue.component('sustainability', Sustainability)
Vue.component('ratings', Ratings)
Vue.component('about', About)
Vue.component('media-booklets', MediaBooklets)
Vue.component('media-photos', MediaPhotos)
Vue.component('media-photos-detail', MediaPhotosDetail)
Vue.component('media-identity', MediaIdentity)
Vue.component('media-identity-detail', MediaIdentityDetail)
Vue.component('site-map', SiteMap)
Vue.component('investors-news', InvestorsNews)
Vue.component('subscribe', Subscribe)
Vue.component('employee', Employee)
Vue.component('careers', Careers)
Vue.component('careers-vacancies', CareersVacancies)
Vue.component('history', History)
Vue.component('company-strategy', CompanyStrategy)
Vue.component('business-model', BusinessModel)
Vue.component('media-kit', MediaKit)
Vue.component('media-kit-detail', MediaKitDetail)
Vue.component('forwarding-and-logistics-form', ForwardingAndLogisticsForm)
Vue.component('special-equipment', SpecialEquipment)
Vue.component('special-equipment-detail', SpecialEquipmentDetail)
Vue.component('special-equipment-price', SpecialEquipmentPrice)

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
    './components',
    true,
    /Z[\w-]+\/index\.js$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = fileName.split('/')[1]
    Vue.component(componentName, componentConfig.default || componentConfig)
})

if (process.env.NODE_ENV !== 'production') {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
        showNotyfications(`${msg}<br>${url}#${lineNo}`, {
            type: 'error'
        })
    }

    // Vue.config.errorHandler = function (err, vm, info) {
    //     let errMsg = `Error: ${err.toString()}`
    //     let infoMsg = ''
    //     let componentMsg = ''

    //     if (info) {
    //         infoMsg = `\nInfo: ${info}`
    //     }

    //     if (vm.hasOwnProperty('$options')) {
    //         componentMsg = `\nVm component: ${vm.$options._componentTag}`
    //     }

    //     console.error(`${errMsg}\n${infoMsg}\n${componentMsg}`)
    //     showNotyfications(`${errMsg}<br>${infoMsg}<br>${componentMsg}`, {
    //         type: 'error'
    //     })
    // }

    // Vue.config.warnHandler = function (msg, vm, info) {
    //     let warnMsg = `Warn: ${msg.toString()}`
    //     let infoMsg = ''
    //     let componentMsg = ''

    //     if (info) {
    //         infoMsg = `\nInfo: ${info}`
    //     }

    //     if (vm.hasOwnProperty('$options')) {
    //         componentMsg = `\nVm component: ${vm.$options._componentTag}`
    //     }

    //     console.warn(`${warnMsg}\n${infoMsg}\n${componentMsg}`)
    //     showNotyfications(`${warnMsg}<br>${infoMsg}<br>${componentMsg}`, {
    //         type: 'alert'
    //     })
    // }

    Vue.config.productionTip = false
}

Object.defineProperty(Vue.prototype, '$bus', {
    get () {
        return this.$root.bus
    }
})

Vue.use(VueAxios)

/* eslint-disable no-new */
new Vue({
    el: '#app',
    mixins: [AppDeviceEnquire],
    store,
    data: {
        lang: window.App.page.lang,
        bus: new Vue({}), // temp
        app: window.App
    },
    components: {
        App
    }
})
